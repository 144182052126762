/**
 * Use flag example:
 * 
 *     // someComponent.tsx
 *     const MyComponent = () => {
 *          return (<>
 *              <Alert className='myAlertLarge' severity='info'>Some INFO alert</Alert>
 *              <Alert className='myAlertMedium' severity='error'>Some DANGER alert</Alert>
 *              <Alert className='myAlertSmall' severity='success'>Some SUCCESS alert</Alert>
 *              <Alert className='myAlertSmall' severity='warning'>Some TIP alert</Alert>
 *              <span className='myNormalText'>Some text</span>
 *          </>)
 *     }
 *     // someOtherFile.scss
 *     @use 'src/styles/flag';
 *     
 *     .myAlertLarge {
 *         @include flag.slim(large);
 *     }
 *     
 *     .myAlertMedium {
 *         @include flag.slim;
 *     }
 *     
 *     .myAlertSmall {
 *         @include flag.slim(small);
 *     }
 */
@use 'src/styles/dimension';
@use 'src/styles/font';
@use 'src/styles/spacing';

@mixin slim($size: medium) {
    &.MuiPaper-root {
        @if $size == large {
            padding: spacing.$s-100 spacing.$m-100;
        }
        @if $size == medium {
            padding: spacing.$s-100 spacing.$m-100;
        }
        @if $size == small {
            padding: spacing.$xs-200 spacing.$m-100;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: dimension.$xs-200;
    }
    & .MuiAlert-icon {
        padding: 0;
    }
    &.MuiAlert-color {
        &Info {
            background-color: var(--color-surface-information-light);
            & .MuiAlert-icon {
                color: var(--color-icon-information);
            }
        }
        &Error {
            background-color: var(--color-surface-danger-light);
            & .MuiAlert-icon {
                color: var(--color-icon-danger);
            }
        }
        &Success {
            background-color: var(--color-surface-success-light);
            & .MuiAlert-icon {
                color: var(--color-icon-success);
            }
        }
        &Warning {
            background: var(--gradient-background);
            & .MuiAlert-icon {
                color: var(--color-icon-brand);
            }
        }
    }
    & .MuiAlert-message {
        @if $size == large {
            @include font.regular;
        }
        @if $size == medium {
            @include font.regular(small);
        }
        @if $size == small {
            @include font.regular(xsmall);
        }
        padding: 0;
    }
}