@use 'src/styles/spacing';

.trialBanner {
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  background: rgba(236, 230, 248, 0.60);
  height: 50px;
  margin: 0 spacing.$l-200;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .MuiTypography-root {
    color: #44546F;
    font-size: 16px;

    &.text {
      margin-right: 2%;
    }

    &.daysLeft {
      font-weight: 600;
      padding: 0 15px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    background-color: rgba(209, 186, 253, 0.40);
    transform: skewX(20deg);
    transform-origin: top right;
  }

  &.ending {
    &::after {
      width: 95%;
    }

    & .MuiTypography-root {
      &.text {
        margin-right: 0;
      }
    }
  }

  &.admin {
    margin: 30px 30px 10px;
  }
}