@use 'src/styles/spacing';
@use 'src/styles/dimension';

.message {
    &Wrapper {
        display: flex;
    }

    &Container {
        max-width: 100%;
        display: flex;
        align-items: flex-start;
        flex-grow: 1;
        gap: spacing.$s-100;
        flex-direction: row;

        &.--user {
            flex-direction: row-reverse;
        }
    }

    &Content {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        white-space: nowrap;
        overflow: auto;
    }

    &ContentBody {
        display: flex;
        flex-direction: column;

        &.--user {
            padding: spacing.$s-100 spacing.$m-100;
            border-radius: dimension.$xs;
            background: var(--color-surface-accent-light);
        }
    }

    &Header {
        display: flex;
        align-items: center;

        &.--user {
          justify-content: flex-end;
        }
    }

    &ModelIcon {
        width: spacing.$l-100;
        height: spacing.$l-100;
        min-width: spacing.$l-100;
    }

    &Avatar.MuiAvatar-root {
        margin: spacing.$xs-200 0;
        width: spacing.$l-200;
        height: spacing.$l-200;
        min-width: spacing.$l-200;
        border-radius: spacing.$m-100;

        &.--optimized {
          background: transparent;
          background-image: linear-gradient(90deg, white, white), linear-gradient(149.42deg, #80B6BC -35.18%, #6BC5D1 7.97%, #AD97F8 48.78%, #B591FD 81.43%, #B591FD 81.43%);
          background-clip: padding-box, border-box;
          background-origin: border-box;
          border: 2px solid transparent;
          color: var(--global-text-primary-color);
        }
    }

    &Body {
        position: relative;
        border: none;
        flex-grow: 1;
        max-width: 100%;
      
        &.MuiPaper-root {
          border: none;
        }
      
        & table {
          margin: 10px 0;
      
          & tr {
            border-bottom: 1px solid lightgray;
      
            &:first-child {
              font-weight: bold;
            }
      
            & td {
              padding: 10px;
            }
          }
        }
      
        &.errorMessageContainer {
          padding: 10px 15px;
          color: #4C0A0A;
          background-color: #f8e8e8;
          display: flex;
      
          & .errorMessageWrapper {
            display: flex;
          }
      
          & svg {
            color: #4C0A0A;
            margin-right: 5px;
          }
        }
      }
}