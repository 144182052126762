@use 'src/styles/spacing';
@use 'src/styles/button';

.HoverMenu {
  &_Container {
    display: flex;
  }

  &_Tooltip.MuiTooltip-tooltip {
    color: white;
    background-color: #00373D;
  }

  &_IconButton {
    @include button.icon-contained;
  }
}