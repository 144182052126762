@use 'src/styles/dimension';
@use 'src/styles/font';
@use 'src/styles/spacing';

@mixin accent {
    &.MuiTabs-root {
        min-height: unset;

        & .MuiTabs-indicator, & .MuiTabScrollButton-root {
            display: none;
        }

        & .MuiTab-root {
            @include font.regular(small);
            padding: spacing.$s-100 spacing.$s-200;
            min-height: unset;
            min-width: unset;
            border: 1px solid var(--color-border-default);
            border-right-width: 0px;
            background-color: var(--color-surface-default);
            color: var(--color-text-default);
            &.MuiButton-text {
                padding: spacing.$s-100 spacing.$s-200;
            }
            &:hover {
                background-color: var(--color-surface-accent-light);
                color: var(--color-text-default);
                border-color: var(--color-border-accent-subtle);
                border-width: 1px;
                & + .MuiTab-root {
                    border-left-width: 0;
                }
            }
            &.Mui-selected {
                background-color: var(--color-surface-accent-light);
                color: var(--color-text-default);
                border-color: var(--color-border-accent);
                border-width: 1px;
                &:hover {
                    background-color: var(--color-surface-accent-contrast-light);
                }
                & + .MuiTab-root {
                    border-left-width: 0;
                }
            }
            & .MuiButton-startIcon {
                margin: 0;
                margin-right: spacing.$xs-200;
            }

            &:first-child {
                border-top-left-radius: dimension.$xs;
            }
            &:last-child {
                border-top-right-radius: dimension.$xs;
                border-right-width: 1px;
            }
        }
    }
}

@mixin default {
    border-bottom: 1px solid var(--color-border-divider);

    & button {
        @include font.medium('large');
        color: var(--color-text-subtle);

        &.Mui-selected {
            color: var(--color-text-brand);
            border-bottom: 2px solid var(--color-border-brand);
        }
    }
}