.narusSubscriptionDetails{
  .subscriptionDetails {
    margin: 32px 0 16px 0;
    font-size: 18px;
    font-weight: 600;
    color: var(--Text-Primary, #09151D);
  }

  .billingCadence {
    color: var(--Text-Primary, #09151D);
    font-size: 16px;
    font-weight: 500;
  }

  .billingCadenceButtonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    & > fieldset {
      display: flex;
      flex-flow: wrap;

      .radioButtonCheckedIcon {
        color: #825ECA;
      }

      .periodTitle > .MuiTypography-root {
        color: var(--Dark, #151C33);
        font-family: var(--font-inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .billingCadenceButtonContainer {
      display: flex;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #CFD9DF;
      background: var(--Background-surface-page, #FFF);
      width: 230px;
      padding: 0 16px;

      .billingCadenceSaveChip {
        margin-left: 10px;
        border-radius: 4px;
        background: #CCF4EA;
        font-size: 14px;
        font-weight: 400;
        color: #1A554F;
      }
    }

    .billingCadenceUpdateChip {
      align-self: flex-end;
      color: #1941B0;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      background: #DAEBFF;
    }

    .billingCadenceUpdateInfo {
      color: var(--Text-Primary, #09151D);
      font-size: 14px;
      font-weight: 400;
    }
  }

  .seatsContainer {
    margin-top: 16px;
    display: flex;
    gap: 2em;
    justify-content: space-between;
    flex-wrap: wrap;

    .title.MuiTypography-root {
      color: var(--Text-Primary, #09151D);
      font-size: 16px;
      font-weight: 500;
    }

    .seatsRow {
      display: flex;
      gap: 10px;

      .subtractButton {
        border-radius: 13px;
        background: #C4C3C5;
        border: 0;

        .subtractIcon {
          color: white;
        }
      }

      .addButton {
        border-radius: 13px;
        background: #E7E7E7;
        border: 0;
      }
    }

    .priceSeatsContainer {
      text-align: right;
      align-content: flex-end;
    }

    .pricePerSeat {
      font-size: 16px;
      font-weight: 500;
    }

    .pricePerSeatExplanation {
      text-align: right;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .newSeatsContainer {
    display: flex;
    margin: 8px 0 32px 0;

    .infoIcon {
      width: 16px;
      height: 16px;
      opacity: 0.6;
      margin-right: 4px;
    }

    .newSeatsAvailability {
      display: flex;
      color: var(--Text-Primary, #09151D);
      font-size: 12px;
      font-weight: 400;
    }
  }

  .periodCostContainer {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid var(--Grey-200, #EEE);

    .periodCostTitle.MuiTypography-root {
      color: var(--Text-Primary, #09151D);
      text-align: right;
      font-family: var(--global-font-family);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .periodDescriptionContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;

    }
    .periodCostAmount.MuiTypography-root {
      color: var(--Text-Primary, #09151D);
      text-align: right;
      font-family: var(--global-font-family);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .periodCostNextBilling.MuiTypography-root {
      color: var(--Primary-Main, #825ECA);
      text-align: right;
      font-family: var(--global-font-family);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .actionsContainer {
    margin-top: 16px;
    float: right;
    .actionsWrapper {
      display: flex;
      gap: 16px;

      .saveSubscriptionButton.MuiButtonBase-root:not(.Mui-disabled) {
        background-color: #825ECA;
        color: white;
      }
    }
  }
}
