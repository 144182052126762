@use 'src/styles/dimension';
@use 'src/styles/font';
@use 'src/styles/spacing';

.fileUploadChips {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: spacing.$m-100;
    &--left {
        justify-content: flex-start;
    }
    &--right {
        justify-content: flex-end;
    }

    .fileUploadChip {
        &.MuiChip-root {
            border-radius: dimension.$xs;
            padding: spacing.$s-100;
            height: unset;
            width: 216px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            & .MuiChip-label {
                flex-grow: 1;
            }
        }
        & .MuiChip-deleteIcon {
            margin: 0;
        }
        &.MuiChip-root:not(.MuiChip-colorError) {
            background-color: var(--color-surface-accent-light);

            .MuiChip-label {
                color: var(--color-text-default);
            }
            .MuiChip-deleteIcon {
                color: var(--global-text-primary-color);
                opacity: 0.7;
            }
            .MuiChip-icon {
                color: var(--color-icon-subtlest);
            }
            
        }
        &_LabelContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &_LabelTitleContainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        &_LabelTitleStart.MuiTypography-root {
            @include font.semibold(small);
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &_LabelTitleEnd.MuiTypography-root {
            @include font.semibold(small);
        }
        &_LabelSubtitle.MuiTypography-root {
            @include font.regular(small);
            color: var(--color-text-subtlest);
        }
    }
}