@use 'src/styles/admin';
@use 'src/styles/spacing';

.safeguardContainer {
    @include admin.container;

    .title.MuiTypography-root {
        @include admin.title;
        margin-bottom: spacing.$s-100;
    }

    .subtitle.MuiTypography-root {
        @include admin.titleDescription;
        margin-bottom: spacing.$l-200;
    }

    .sectionTitle.MuiTypography-root {
        @include admin.sectionTitle;
        padding: 12px 0px;
        border-bottom: 1px solid #E0E0E0;
    }
}