/**
 * Spacings are used for margin, padding, gaps, position, width and height dimensioning
 * Use spacings example:
 * 
 *     // someOtherFile.scss
 *     @use 'src/styles/spacing';
 *     
 *     .myClass {
 *         padding: spacing.$m-100;
 *         margin-top: spacing.$l-200;
 *     }
 */


// Figma variables

$xs-100:  0.125rem; //   2px
$xs-200:  0.25rem;  //   4px
$s-100:   0.5rem;   //   8px
$s-200:   0.75rem;  //  12px
$m-100:   1rem;     //  16px
$m-200:   1.25rem;  //  20px
$l-100:   1.5rem;   //  24px
$l-200:   2rem;     //  32px
$xl-100:  2.5rem;   //  40px
$xl-200:  3rem;     //  48px
$xxl-100: 3.5rem;   //  56px
$xxl-200: 4rem;     //  64px
$xxl-300: 4.5rem;   //  72px
$xxl-400: 5rem;     //  80px
$xxl-500: 5.5rem;   //  88px
$xxl-600: 8rem;     // 128px
