@use 'src/styles/button';
@use 'src/styles/chat';
@use 'src/styles/spacing';

.chatInput {
    &Container {
      @include chat.fancyBorder;
      box-sizing: border-box;
  
      .modelDisabledAlert {
        width: fit-content;
      }
    }
  
    &FilesContainer {
      padding: spacing.$m-100 spacing.$m-100 0 spacing.$m-100;
      display: flex;
    }
  
    &TextField {
      @include chat.textField;
    }
  
    &Settings {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 spacing.$m-100 spacing.$m-100 spacing.$m-100;
  
      &Actions {
          display: flex;
          align-items: center;
          gap: spacing.$s-100;
      }
    }
  
    &IconButton {
      @include button.icon;
      &.MuiIconButton-root {
          width: spacing.$l-200;
          height: spacing.$l-200;
      }
  
      &Badge > .MuiBadge-badge {
          background-color: var(--color-surface-brand-contrast);
          width: spacing.$m-100;
          height: spacing.$m-100;
      }
    }
  
    &SendButton {
        @include button.icon-contained;
        height: spacing.$l-200;
    }
  }