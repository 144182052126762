body {
  margin: 0;
  font-family: var(--global-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAF9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {

  /* LEGACY */
  --global-primary-color: #015f6b;
  --global-default-color: #BBBBB2;
  --global-text-primary-color: #44546F;
  --global-text-on-primary-color: white;
  --global-outline-color: #E0E0E0;
  --global-purple-color: #825ECA;

  --global-font-family: 'Avenir Next', 'Open Sans', 'Segoe UI,', 'Roboto', sans-serif;
  --font-inter: 'Inter', sans-serif;

  --card-text-color: #151C33;
  --card-icon-color: #684BA2;
  --gradient-info: radial-gradient(89.52% 134.44% at 22.95% 0%, #A178FA0B 0%, #A375FC0B 17.71%, #6BC5D10E 35.86%, #006D7A0B 57.9%, #A375FC08 91.9%), white;

  /**
   * NARUS STYLE
   */
  /* BRAND COLORS */  
  /* Navy */
  --color-navy-50:  #F2F7FB;
  --color-navy-100: #E6F0F6;
  --color-navy-200: #CDE1EE;
  --color-navy-300: #82B4D4;
  --color-navy-400: #5096C3;
  --color-navy-500: #0569A9;
  --color-navy-600: #045487;
  --color-navy-700: #033F65;
  --color-navy-800: #022033;
  --color-navy: var(--color-navy-500);
  /* Purple */
  --color-purple-50:  #FAF8FF;
  --color-purple-100: #F6F1FF;
  --color-purple-200: #EDE3FE;
  --color-purple-300: #C8ACFD;
  --color-purple-400: #B591FD;
  --color-purple-500: #A375FC;
  --color-purple-600: #825ECA;
  --color-purple-700: #624697;
  --color-purple-800: #31134C;
  --color-purple: var(--color-purple-500);
  /* Teal */
  --color-teal-50:  #F4FAFB;
  --color-teal-100: #E9F5F6;
  --color-teal-200: #D4EBEE;
  --color-teal-300: #93CDD3;
  --color-teal-400: #69B9C2;
  --color-teal-500: #289BA8;
  --color-teal-600: #207C86;
  --color-teal-700: #185D65;
  --color-teal-800: #0C2F32;
  --color-teal: var(--color-teal-500);

  /* NEUTRAL COLORS */
  /* Beige */
  --color-beige-100: #FDFDFC;
  --color-beige-200: #F9F9F5;
  --color-beige-300: #F2F2EC;
  --color-beige-400: #EEEEE5;
  --color-beige-500: #EAEADF;
  --color-beige-600: #BBBBB2;
  --color-beige-700: #8C8C86;
  --color-beige-800: #464643;
  --color-beige: var(--color-beige-500);
  /* Grey */  
  --color-grey-50:  #F2F7FA;
  --color-grey-100: #EAF0F4;
  --color-grey-200: #D9E1E5;
  --color-grey-300: #B4BCC1;
  --color-grey-400: #8A949B;
  --color-grey-500: #747E84;
  --color-grey-600: #5C666D;
  --color-grey-700: #465056;
  --color-grey-800: #2E383F;
  --color-grey-900: #182228;
  --color-grey: var(--color-grey-500);
  /* White */
  --color-white: #FFFFFF;
  /* Dark */
  --color-dark: #010B11;

  /* SEMANTIC COLORS */
  /* Blue */
  --color-blue-50:  #EFF7FF;
  --color-blue-100: #DAEBFF;
  --color-blue-200: #BEDDFF;
  --color-blue-300: #91C9FF;
  --color-blue-400: #5DAAFD;
  --color-blue-500: #3F8CFA;
  --color-blue-600: #2168EF;
  --color-blue-700: #1952DC;
  --color-blue-800: #1941B0;
  --color-blue-900: #1C3D8C;
  --color-blue-950: #162655;
  --color-blue: var(--color-blue-500);
  /* Green */  
  --color-green-50:  #F2FBF9;
  --color-green-100: #CCF4EA;
  --color-green-200: #A4EBDA;
  --color-green-300: #6FD9C4;
  --color-green-400: #41C0AB;
  --color-green-500: #28A491;
  --color-green-600: #1E8376;
  --color-green-700: #1C6960;
  --color-green-800: #1A554F;
  --color-green-900: #1A4742;
  --color-green-950: #092A28;
  --color-green: var(--color-green-500);
  /* Red */  
  --color-red-50:  #FEF2F2;
  --color-red-100: #FFE1E2;
  --color-red-200: #FFC7C8;
  --color-red-300: #FEA3A5;
  --color-red-400: #FB6E71;
  --color-red-500: #F24144;
  --color-red-600: #E02225;
  --color-red-700: #BC191C;
  --color-red-800: #9B191B;
  --color-red-900: #811B1D;
  --color-red-950: #46090A;
  --color-red: var(--color-red-500);
  /* Orange */  
  --color-orange-50:  #FFFBEC;
  --color-orange-100: #FFF6D3;
  --color-orange-200: #FFEAA5;
  --color-orange-300: #FFD86D;
  --color-orange-400: #FFBB32;
  --color-orange-500: #FFA40A;
  --color-orange-600: #FF8C00;
  --color-orange-700: #CC6702;
  --color-orange-800: #8D3B09;
  --color-orange-900: #592B04;
  --color-orange-950: #3D1C00;
  --color-orange: var(--color-orange-500);

  /* SHADOWS */
  --shadow-default: 4px 4px 14px 8px #0569A90A;

  /* GRADIENTS */
  --gradient-background: radial-gradient(142.13% 141.42% at 0% 0%, #5096C31F 0%, #69B9C212 53.46%, #EDE3FE1F 100%);
  --gradient-component: linear-gradient(131deg, #5096C333 -0.71%, #69B9C21F 51.98%, #EDE3FE33 103.5%);

  /* COLOR TEXT */
  --color-text-default:               var(--color-dark);
  --color-text-inverse:               var(--color-white);
  --color-text-subtle:                var(--color-grey-700);
  --color-text-subtlest:              var(--color-grey-600);
  --color-text-disabled:              var(--color-grey-600);
  --color-text-disabled-contrast:     var(--color-grey-700);
  --color-text-brand:                 var(--color-purple-600);
  --color-text-brand-contrast:        var(--color-purple-800);
  --color-text-danger:                var(--color-red-700);
  --color-text-danger-contrast:       var(--color-red-800);
  --color-text-warning-contrast:      var(--color-orange-800);
  --color-text-success:               var(--color-green-600);
  --color-text-success-contrast:      var(--color-green-800);
  --color-text-information:           var(--color-blue-600);
  --color-text-information-contrast:  var(--color-blue-800);
  --color-text-accent:                var(--color-teal-600);
  --color-text-accent-contrast:       var(--color-teal-800);
  --color-text-tertiary:              var(--color-navy-600);
  --color-text-tertiary-contrast:     var(--color-navy-800);

  /* COLOR SURFACE */
  --color-surface-default:                  var(--color-white);
  --color-surface-brand:                    var(--color-purple-600);
  --color-surface-brand-contrast:           var(--color-purple-700);
  --color-surface-danger:                   var(--color-red-600);
  --color-surface-danger-contrast:          var(--color-red-800);
  --color-surface-brand-light:              var(--color-purple-50);
  --color-surface-brand-contrast-light:     var(--color-purple-100);
  --color-surface-accent-light:             var(--color-teal-50);
  --color-surface-accent-contrast-light:    var(--color-teal-100);
  --color-surface-tertiary-light:           var(--color-navy-50);
  --color-surface-tertiary-contrast-light:  var(--color-navy-100);
  --color-surface-disabled-light:           var(--color-grey-100);
  --color-surface-danger-light:             var(--color-red-50);
  --color-surface-danger-contrast-light:    var(--color-red-100);
  --color-surface-success-light:            var(--color-green-50);
  --color-surface-success-contrast-light:   var(--color-green-100);
  --color-surface-warning-light:            var(--color-orange-50);
  --color-surface-warning-contrast-light:   var(--color-orange-100);
  --color-surface-information-light:        var(--color-blue-50);
  --color-surface-information-contrast-light:  var(--color-blue-100);
  --color-surface-background: color-mix(in srgb, var(--color-navy), transparent 60%);

  /* COLOR ICON */
  --color-icon-default:      var(--color-grey-700);
  --color-icon-inverse:      var(--color-white);
  --color-icon-subtle:       var(--color-grey-600);
  --color-icon-subtlest:     var(--color-grey-500);
  --color-icon-disabled:     var(--color-grey-400);
  --color-icon-brand:        var(--color-purple-600);
  --color-icon-brand-subtle: var(--color-purple-300);
  --color-icon-danger:       var(--color-red-600);
  --color-icon-warning:      var(--color-orange-600);
  --color-icon-success:      var(--color-green-600);
  --color-icon-information:  var(--color-blue-600);
  --color-icon-accent:       var(--color-teal-600);
  --color-icon-tertiary:     var(--color-navy-600);

  /* COLOR BORDER */
  --color-border-default:         var(--color-grey-200);
  --color-border-disabled:        var(--color-grey-100);
  --color-border-divider:         var(--color-grey-100);
  --color-border-neutral:         var(--color-grey-400);
  --color-border-brand:           var(--color-purple-600);
  --color-border-brand-subtle:    var(--color-purple-300);
  --color-border-accent:          var(--color-teal-600);
  --color-border-accent-subtle:   var(--color-teal-300);
  --color-border-tertiary:        var(--color-navy-600);
  --color-border-tertiary-subtle: var(--color-navy-300);
  --color-border-danger:          var(--color-red-600);
  --color-border-warning:         var(--color-orange-600);
  --color-border-success:         var(--color-green-600);
  --color-border-information:     var(--color-blue-600);

  /* COLOR LINK */
  --color-text-link:          var(--color-blue-700);
  --color-text-link-pressed:  var(--color-blue-800);

  /* COLOR SKELETON */
  --color-skeleton:         var(--color-grey-200);
  --color-skeleton-subtle:  var(--color-grey-100);

  /* ELEVATION /SHADOW */
  --drop-shadow: drop-shadow(4px 4px 14px rgba(5, 105, 169, 0.04));  

  /* BRAND TYPOGRAPHY */
  --font-brand: 'Inter', sans-serif;

}
