/**
 * Use button example:
 * 
 *     // someComponent.tsx
 *     const MyComponent = () => {
 *          return (<>
 *              <Button className='myButton'>Some text</Button>
 *              <Button className='myButton' variant='outline'>Some text</Button>
 *              <Button className='myButton' variant='contained'>Some text</Button>
 *              <Button className='myButton' size='small'>Some text</Button>
 *              <Button className='myButton' size='large'>Some text</Button>
 *              <Button className='myButton' color='error'>Some text</Button>
 *              // ...and other combinations
 *          </>)
 *     }
 *     // someOtherFile.scss
 *     @use 'src/styles/button';
 *     
 *     .myButton {
 *         @include button.default;
 *     }
 */

@use 'src/styles/font';
@use 'src/styles/spacing';

@mixin _size {
    &.MuiButton-sizeLarge {
        padding: spacing.$s-100 spacing.$l-100;
        @include font.medium;
        &.MuiButton-text {
            padding: spacing.$s-100 spacing.$s-200;
        }
    }
    &.MuiButton-sizeMedium {
        padding: spacing.$s-100 spacing.$m-100;
        @include font.medium(small);
        &.MuiButton-text {
            padding: spacing.$s-100 spacing.$s-100;
        }
    }
    &.MuiButton-sizeSmall {
        padding: spacing.$xs-200 spacing.$s-200;
        @include font.medium(small);
    }
}

@mixin default {
    @include _size;
    &.MuiButton-contained  {
        background-color: var(--color-surface-brand);
        color: var(--color-text-inverse);
        &:hover {
            background-color: var(--color-surface-brand-contrast);
        }
        &.Mui-disabled {
            background-color: var(--color-surface-disabled-light);
            color: var(--color-text-disabled-contrast);
        }
        &Error {
            background-color: var(--color-surface-danger);
            &:hover {
                background-color: var(--color-surface-danger-contrast);
            }
        }
    }
    &.MuiButton-outlined {
        border-color: var(--color-border-brand);
        color: var(--color-text-brand);
        &:hover {
            background-color: var(--color-surface-brand-contrast-light);
            color: var(--color-text-brand);
        }
        &.Mui-disabled {
            background-color: transparent;
            border-color: var(--color-text-disabled-contrast);
            color: var(--color-text-disabled-contrast);
        }
        &Error {
            color: var(--color-text-danger);
            border-color: var(--color-border-danger);
            &:hover {
                color: var(--color-text-danger);
                background-color: var(--color-surface-danger-contrast-light);
            }
        }
    }
    &.MuiButton-text {
        color: var(--color-text-brand);
        &:hover {
            background-color: var(--color-surface-brand-contrast-light);
        }
        &.Mui-disabled {
            background-color: transparent;
            color: var(--color-text-disabled-contrast);
        }
        &Error {
            color: var(--color-text-danger);
            &:hover {
                background-color: var(--color-surface-danger-contrast-light);
            }
        }
    }
}

@mixin icon {
    &.MuiIconButton-root {
        color: var(--color-icon-brand);
        &:hover {
            background-color: var(--color-surface-brand-contrast-light);
        }
        &.Mui-disabled {
            background-color: transparent;
            color: var(--color-icon-disabled);
        }
        &Error {
            color: var(--color-text-danger);
            &:hover {
                background-color: var(--color-surface-danger-contrast-light);
            }
        }
    }
}

@mixin icon-contained {
    @include default;
    &.MuiButton-root {
        min-width: unset;
    }
    & .MuiButton-icon {
        margin: 0;
    }

}

@mixin accent {
    &.MuiButton-root {
        @include font.regular;
        &.MuiButton-text {
            padding: spacing.$s-100 spacing.$s-200;
        }
        border: 1px solid var(--color-border-default);
        background-color: var(--color-surface-default);
        color: var(--color-text-default);
        &:hover {
            background-color: var(--color-surface-accent-light);
            color: var(--color-text-default);
            border-color: var(--color-border-accent-subtle);
        }
        &.Mui-selected {
            background-color: var(--color-surface-accent-light);
            color: var(--color-text-default);
            border-color: var(--color-border-accent);
            &:hover {
                background-color: var(--color-surface-accent-contrast-light);
            }
        }
        & .MuiButton-startIcon {
            margin: 0;
            margin-right: spacing.$xs-200;
        }
    }
}
