@use 'src/styles/panel';
@use 'src/styles/font';
@use 'src/styles/spacing';
@use 'src/styles/button';

$containerPadding: spacing.$l-200;
$contentGap: spacing.$xxl-500;
$contentGapLarge: spacing.$l-200;
$sideMinWidth: 290px;
$sideMaxWidth: 366px;
$mainMinWidth: 630px;
$mainMaxWidth: 804px;
$mainHeight: 770px;
$mediaWidthLarge: calc($sideMaxWidth + $mainMaxWidth +  $containerPadding + $containerPadding + $contentGap);
$mediaWidthMedium: calc($sideMinWidth + $mainMaxWidth + $containerPadding + $containerPadding + $contentGapLarge);
$mediaWidthSmall: calc($sideMinWidth + $mainMinWidth + $containerPadding + $containerPadding + $contentGapLarge);

.AccountCreation {    
    &_Container {
        padding: $containerPadding;
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        display: flex;
        align-items: center;
        background: var(--gradient-background);
    }

    &_Content {
        width: 100%;
        height: $mainHeight;
        max-height: calc(100vh - 2 * $containerPadding);
        display: flex;
        justify-content: center;
        align-items: stretch;
        gap: spacing.$xxl-400;
    }

    &_Side {
        width: $sideMaxWidth;
        display: flex;
        flex-direction: column;
        justify-self: flex-end;
        justify-content: flex-start;
        align-items: flex-start;
        gap: spacing.$xl-100;
    }

    &_Main {
        @include panel.default;
        width: $mainMaxWidth;
        min-width: $mainMaxWidth;
        max-width: $mainMaxWidth;
        padding: spacing.$xxl-400 spacing.$xxl-200;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_Side {
        &Logo {
            margin-bottom: spacing.$xl-100;
        }

        &Title {
            @include font.typography-semibold(h5);
        }

        &Subtitle {
            @include font.typography-regular;
        }

        &Action {
            @include button.default;
        }

        &Help {
            margin-top: auto;
            margin-bottom: 128px;
            display: flex;
            align-items: center;
            gap: spacing.$s-100;
            cursor: pointer;

            &Text.MuiTypography-root {
                @include font.regular;
                color: var(--color-text-subtle);
            }
        }
    }

    &_Avatar {
        position: fixed;
        top: spacing.$l-100;
        right: spacing.$xl-100;
    }

    @media (max-width: $mediaWidthLarge) {
        &_Content {
            justify-content: space-between;
            gap: $contentGapLarge;            
        }

        &_Side {
            width: auto;
            min-width: $sideMinWidth;
            max-width: $sideMaxWidth;
        }
    }

    @media (max-width: $mediaWidthMedium) {
        &_Content {
            justify-content: center;
        }

        &_Side {
            max-width: $sideMinWidth;
        }

        &_Main {
            padding: spacing.$xxl-400 spacing.$xl-100;
            width: $mainMaxWidth;
            min-width: $mainMinWidth;
        }
    }

    @media (max-width: $mediaWidthSmall) {
        &_Container {
            overflow: scroll;
        }

        &_Content {
            justify-content: flex-start;
        }
    }
}