@use 'src/styles/button';
@use 'src/styles/font';
@use 'src/styles/spacing';
@use 'src/styles/tabs';

.AiChat {
    &_Container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: spacing.$s-100;
        overflow: hidden;
    }
    &_Header {
        &Container {
            display: grid;
        }
        &Line, &Content {
            grid-area: 1 / 1 / 2 / 2;
        }
        &Line {
            border-bottom: 1px solid var(--color-border-default);
        }
        &Content {
            padding: 0px spacing.$xl-100;
            display: flex;
            align-items: center;
            gap: spacing.$m-100;
            overflow-x: auto;
        }
    }
    &_Back {
        @include button.icon;
    }
    &_Tabs {
        @include tabs.accent;
        & .inactive.MuiTab-iconWrapper {
            filter: grayscale(1);
        }
    }
    &_Content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
    }
    &_Quote.MuiTypography-root {
        @include font.regular(xsmall);
        color: var(--color-text-subtlest);
        padding-top: spacing.$s-100;
        text-align: center;
    }
}
