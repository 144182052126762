@use 'src/styles/font';
@use 'src/styles/spacing';

.AccountCreationOnboarding {
    &_Content {
        gap: spacing.$m-100;
    }
    
    &_TitleContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &_Title {
        @include font.typography-semibold(h4);
        height: spacing.$xxl-200; 
    }

    &_Description {
        @include font.typography-regular;
        max-width: 430px;
    }
    
    &_List {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: spacing.$l-100;
    }

    &_List {
        &Icon {
            color: var(--color-icon-brand);
        }

        &Item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: spacing.$s-100;
        }

        &Text {
            @include font.typography-regular;
        }
    }
}