
@use 'src/styles/chat';
@use 'src/styles/flag';
@use 'src/styles/font';
@use 'src/styles/spacing';

.NewChat {
  &_Container {
    box-sizing: content-box;
    margin: 0 auto;
    padding: spacing.$xxl-600 spacing.$l-200 0 spacing.$l-200;
    width: calc(100% - spacing.$l-200 - spacing.$l-200);
    max-width: chat.$max-width;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    flex-grow: 1;
    overflow: hidden;
  }
  &_Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  &_Title.MuiTypography-root {
    @include font.semibold(h5);
    margin-bottom: spacing.$l-100;
  }
  &_TitleAlert {
    @include flag.slim(small);
    margin-bottom: spacing.$l-200;
  }
}
