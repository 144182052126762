@use 'src/styles/button';
@use 'src/styles/spacing';
@use 'src/styles/tooltip';

.TaskBar {
    &_Container {
        padding: 0 spacing.$l-200;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: spacing.$s-100;
    }

    &_IconButton {
        @include button.icon;
    }

    &_Button {
        @include button.default;
    }

    &_Container {
        & .MuiDivider-root {
            border-color: var(--color-border-divider);
            height: spacing.$l-100;
            margin: auto 0;
        }
    }

    &_Tooltip {
        @include tooltip.default;
    }
}