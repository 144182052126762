@use 'src/styles/button';

.dialogContainer {
  & .MuiPaper-root {
    box-shadow: none;
    border: solid 1px var(--global-outline-color);
    border-radius: 4px;
    width: 50%;
  }

  & .dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px var(--global-outline-color);
  }

  & .dialogContent {
    &.MuiDialogContent-root {
      padding: 16px 24px;
    }
    & .MuiTypography-root{
      color: var(--global-text-primary-color);
    }

    & .dialogContentText:nth-child(2),
    & .dialogContentText:nth-last-child(2) {
      padding-bottom: 16px;
    }
  }

  & .newGroupNameTextfield {
    margin-top: 20px
  }
  
  & .dialogCloseIcon {
    cursor: pointer;
  }

  & .deleteButton.noBorderDeleteButton {
    border: none;
  }

  & .primaryButton, .secondaryButton {
    @include button.default
  }
}

