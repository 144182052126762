@use 'src/styles/spacing';
@use 'src/styles/button';

.AiModelSelector {
  &_Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    gap: spacing.$s-100;
    margin-bottom: spacing.$l-200;
  }
  &_Button {
    @include button.accent;
  }
}