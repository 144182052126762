@use 'src/styles/font';
@use 'src/styles/spacing';
@use 'src/styles/button';
@use 'src/styles/admin';
@use 'src/styles/tabs';

.globalSettings {
  @include admin.container;

  .settingsTabContainer {
	margin-top: spacing.$m-100;
	@include tabs.default;
  }

  & .title {
	@include admin.title;
  }

  & .sectionTitle {
	@include font.typography-semibold('large');
	margin-top: spacing.$l-200;
	margin-bottom: spacing.$s-100;
  }

  & .body {
	@include font.typography-regular('base');
  }
}

.settingContainer {
  .organizationIconWrapper {
	display: flex;
	align-items: center;
	margin-top: spacing.$l-100;
	gap: spacing.$s-100;

	.organizationIconContainer svg {
	  border-radius: spacing.$xs-200;
	  border: 1px solid var(--color-border-default);
	  width: spacing.$xl-100;
	  height: spacing.$xl-100;
	}
  }

  .organizationNameContainer {
	flex-grow: 1;
  }

  .addContainer {
	display: flex;
	gap: spacing.$s-100;
	align-items: start;
	margin-top: spacing.$l-100;

	& :first-child {
	  flex-grow: 1;
	}
  }

  .helperContainer {
	display: flex;
	gap: spacing.$xs-100;
	align-items: baseline;

	& svg {
	  width: spacing.$s-200;
	  height: spacing.$s-200;
	  color: var(--color-icon-subtle);

	}

	& > .helperTextContainer {
	  @include font.regular('xsmall');
	  color: var(--color-text-subtle);
	}
  }

  .saveButton {
	@include button.default;
  }

  .domainNameContainer {
	border-radius: spacing.$xs-200;
	border: 1px solid var(--color-border-default);
	margin-top: spacing.$m-100;
	padding: spacing.$s-100 spacing.$m-100;
  }

  .accountDomainRow {
	display: flex;
	border-bottom: 1px solid var(--color-border-divider);
	padding: spacing.$s-100 0;

	&:last-child {
	  border-bottom: none;
	}

	.accountDomainName {
	  flex-grow: 1;
	  align-content: center;
	  color: var(--color-text-default);

	  & .MuiTypography-root {
		@include font.typography-regular('base');
	  }
	}
  }

  .domainActionIcon {
	@include button.icon;
  }
}




