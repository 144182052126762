@use 'src/styles/dimension';
@use 'src/styles/font';

$max-width: 770px;

@mixin fancyBorder {
    position: relative;
    border-radius: dimension.$xs;
  
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border: 1px solid transparent;
      background: conic-gradient(from 180deg at 50% 50%, #80B6BC 0deg, #6BC5D1 133.2deg, #8AAFE4 250.2deg, #B591FD 351deg, #80B6BC 360deg) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      border-radius: dimension.$xs;
    }
}

@mixin textField {
    & > div > .MuiOutlinedInput-notchedOutline {
        border: none;
    }
  
    & > .MuiInputBase-root.Mui-focused {
        outline: none;
        border: none;
    }

    & .MuiInputBase-input {
        @include font.regular;

        &:focus {
            border: none;
            outline: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow:none;
            resize: none;
        }
    }
}