@use 'src/styles/chat';
@use 'src/styles/spacing';

.chat {
  &Container {
    box-sizing: content-box;
    margin: 0 auto;
    padding: 0 spacing.$l-200 0 spacing.$l-200;
    width: calc(100% - spacing.$l-200 - spacing.$l-200);
    max-width: chat.$max-width;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    gap: spacing.$xl-200;
    overflow: hidden;
  }

  &MessageContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &MessageList {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: spacing.$xxl-300;
  }  
}

//LEGACY STYLES TODO: move styles where they correspond and nest them properly
.userNameContainer {
  margin: 0 8px;
  color: #0D082C;

  & p {
	font-size: 16px;
	font-weight: 600;
  }
}

.botNameContainer {
  margin: 0 8px 15px;
  color: #0D082C;

  & p {
	font-size: 16px;
	font-weight: 600;
  }
}

.promptResultHelperText, .promptResultHelperText.MuiTypography-body1 {
  color: rgba(13, 8, 44, 0.40);
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  margin-left: 15px;
}

.advancedSettingsContainer {
  padding: 0 18px;
}

.arrowIconContainer {
  background-color: var(--global-primary-color);
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  display: flex;

  & > svg {
	color: #FFFFFF;
	font-size: 18px;
  }

  &.disabled {
    cursor: auto;
    background-color: rgba(204, 204, 204, 1);
  }
}

.helpIcon {
  margin-left: 5px;
  margin-right: 7px;
}

.optimizedIcon {
  width: 12px !important;
  height: 12px !important;
}

.optimizedContainer {
  display: flex;
  align-items: center;
  margin: spacing.$s-100 0;
  padding: 4px 6px 4px 6px;
  gap: 5px;
  border-radius: 100px;
  background: linear-gradient(180deg, rgba(128, 182, 188, 0.4) -2.56%, rgba(107, 197, 209, 0.4) 38.71%, rgba(173, 151, 248, 0.4) 77.74%, rgba(181, 145, 253, 0.4) 108.97%);
  color: #523B7E;

  & .optimizedText.MuiTypography-root {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
  }
}

.messageContainer {

  & .loadingText {
	margin: 0 8px;
  }

}

.optimizedTooltipHeader.MuiTypography-root {
  font-weight: 600;
  justify-self: flex-end;
}

.loadingText.MuiTypography-root {
  color: var(--global-primary-color);
  margin-left: 8px;
}



.tryAgainButtonContainer {
  display: flex;
  justify-content: flex-end;

  & .tryAgainButton.MuiButtonBase-root {
	background-color: white;
	font-size: 14px;
  }
}

.refinePromptButton.MuiButtonBase-root {
  margin-top: 17px
}

.loadingText.MuiTypography-body1 {
  margin-left: 10px;
  color: var(--global-primary-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}